// This is where you dynamically change information for an identical site with a different domain.
// Don't forget to import any images you may need

import dynamicSiteConfig from '../../../../src/utils/dynamic/dynamicSiteConfig';

/**
 *
 * @param {*} config - config object. Use this to alter any config options you need to
 * @param {*} setDocumentTitle - function. Needed to implicitly change the document title
 * @param {*} query - params object.
 */

const customConfig = config => {
  const url = document.URL;
  const altUrl = 'NEW URL HERE';

  if (url.includes(altUrl)) {
    // change any config information you need here
    // config.organicLink = '';
    // config.headline = 'New headline';
    // config.offerId = 0000;
    // setDocumentTitle('New Title');
    // document.getElementById('header-image').src = newLogo;
  }

  /**
   * updates main config from dynamicSites config
   * @param  {object} config
   */
  dynamicSiteConfig(config);
};

export default customConfig;
